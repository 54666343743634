* {
  padding: 0;
  margin: 0;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

svg {
  cursor: pointer;
}

.light-gray {
  color: #43434D !important;
}

.light-red {
  color: #FF6363 !important;
}
/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
// @media (prefers-reduced-motion: reduce) {
//   html:focus-within {
//     scroll-behavior: auto;
//   }

//   *,
//   *::before,
//   *::after {
//     animation-duration: 0.01ms !important;
//     animation-iteration-count: 1 !important;
//     transition-duration: 0.01ms !important;
//     scroll-behavior: auto !important;
//   }
// }

html {
  min-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #f0f0f0;
  font-family: 'Sora', sans-serif;
}

/* 
body,
#root {
  /* height: 100%;
  min-height: 100%; */
/* } */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Archivo', sans-serif;
  color: #1f2023;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  box-sizing: border-box;
}

.content_card {
  display: flex;
  flex-direction: column;
  height: calc(100% - 30px);
  justify-content: space-between;
  overflow: hidden;
}

.notoppad {
  padding-top: 20px !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.mainApp {
  width: 100%;
  max-width: 100%;
  padding: 0 16px 30px 16px;
}

.animated {
  animation-duration: 700ms !important;
}

.td-100 {
  animation-delay: 100ms;
}

.td-200 {
  animation-delay: 200ms;
}

.td-300 {
  animation-delay: 300ms;
}

.td-400 {
  animation-delay: 400ms;
}

.td-500 {
  animation-delay: 500ms;
}

.td-600 {
  animation-delay: 600ms;
}

.td-700 {
  animation-delay: 700ms;
}

// @media (max-width: 768px) {
//   html {
//     /* background: #fff; */
//   }
//   .main__container {
//     box-shadow: none !important;
//   }
//   .mainApp {
//     padding: 30px 0;
//     width: 100%;
//   }
//   .main__container,
//   .main__card {
//     /* padding: 0 !important; */
//   }
//   .main__card {
//     width: 100% !important;
//     border-radius: 0 !important;
//     /* box-shadow: none !important; */
//   }
//   .main-header {
//     padding-bottom: 30px;
//   }
// }
.spinner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 200px;
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #f0f0f0;
}

.spinner__logo {
  display: block;
}

.spinner_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner_container .expired-payment-header-content {
  margin-top: 15px;
  font-size: 12px;
}

.expired-payment-header {
  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 0.15px;
    color: #000000;
    text-align: center;
    margin-bottom: 10px;
  }

  &-content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 45px;
  }
}

.payment-header {
  &__warning {
    height: 64px;
    width: 'auto';
    display: 'block';
    margin: auto;
    margin-bottom: 20px;
    margin-top: 37px;
  }
}


.btn {
  &__grey {
    background: rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 14px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    border: none;
    height: 52px;

    transition: all 300ms ease-in-out;

    &:disabled {
      opacity: 0.3;
      background: rgba(0, 0, 0, 0.05);
    }

    &:hover {
      opacity: 0.7;
      background: rgba(0, 0, 0, 0.05);
    }

    &:disabled:hover {
      cursor: not-allowed;
      opacity: 0.3;
      background: rgba(0, 0, 0, 0.05);
    }
  }

  &__dark {
    height: 52px;
    background: #000000;
    width: 100%;
    padding: 14px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    border: none;
    transition: all 300ms ease-in-out;

    &:disabled {
      opacity: 0.3;
      background: rgba(0, 0, 0, 0.3);
    }

    &:hover {
      opacity: 0.7;
      background: rgba(0, 0, 0, 0.7);
    }

    &:disabled:hover {
      cursor: not-allowed;
      opacity: 0.3;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  &__transparent {
    border: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 16px !important;
    font-style: normal;
    line-height: 24px;
    text-align: center;
    color: #43434D;
    padding: 14px;
    width: 100%;
    border-color: transparent;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    // &:hover {    
    //   transform: translateY(-2px);
    // }
  }

  &__red {
    height: 52px;
    width: 100%;
    padding: 14px;
    background: #d24339;
    border-radius: 10px;
    color: #ffffff;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 300ms ease-in-out;

    &:hover {
      background: rgb(210, 67, 57, 0.8);
    }
  }

  &__transparent__red {
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #d24339;
    padding: 14px;
    width: 100%;
    border-radius: 10px;
    border-color: transparent;
    cursor: pointer;
    margin-top: 20px;
    transition: all 300ms ease-in-out;

    &:hover {
      transform: translateY(-2px);
    }
  }
}

.position-relative {
  position: relative;
}

.line-stroke {
  height: 1px;
  background-color: var(--grey-100, #F5F5F6);
  width: 100%;
}

.hover-animate-vertically {
  transition: all 300ms ease-in-out;

  &:hover {
    transform: translateY(-2px);
  }
}

.link-span {
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
  color: #000000;
}

.input_field {
  height: 3rem;
  border-radius: 10px;
  border: none;
  padding: 16px;
  color: rgba(0, 0, 0, 1) !important;
  background-color: #F5F5F6 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  outline-color: rgba(0, 0, 0, 1);
  transition: all 300ms ease-in-out;
  width: 100%;
  outline: none;

  &:focus {
    outline: 3px solid black;
  }
}

.title-lable-container {
  padding: 0 0 8px 0;
  ;
}

.title_label {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
}

.request_error {
  color: #d14343;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  transition: all 300ms ease-in-out;
}

.input_field::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.26em;
  color: #5E5E5E !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #F5F5F6 inset !important;
}

.p-36 {
  padding: 36px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.no-margin-top {
  margin-top: 0 !important;
}


.mt-41 {
  margin-top: 41px;
}

.mt-48 {
  margin-top: 48px !important;
}


.mt-22 {
  margin-top: 22px;
}

.mt-36 {
  margin-top: 36px !important;
}
.mt-24 {
  margin-top: 24px !important;
}

.mt-74 {
  margin-top: 74px;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-35 {
  margin-top: 35px !important;
}
.mt-16 {
  margin-top: 16px !important;
}

.w-100 {
  width: 100%;
}

.payment-header {
  border: none;
  margin-bottom: 45px;
  line-height: 20px;
  padding-bottom: 0;
}

.payment-header__warning {
  margin-top: 35px !important;
  margin-bottom: 25px !important;
}

.content_container {
  div {
    height: 100%;
  }
}

.border_top_left {
  border-top-left-radius: 10px;

}

.border_top_right {
  border-top-right-radius: 10px;
}

.border_bottom_left {
  // border-bottom-left-radius: 10px;
}

.border_bottom_right {
  // border-bottom-right-radius: 10px;
}

.lng_btn {
  display: flex;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-start {
  justify-content: flex-start;
}

.align-item-start {
  align-items: start;
}

.paragraph {
  display: inline;
  padding: 0;
  width: max-content;
  font-size: 12px;
}

.font-archivo-500 {
  color: var(--black, #000);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.font-archivo-700 {
  color: var(--black, #000);
  text-align: center;
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 140% */
  letter-spacing: 0.25px;
}

.font-archivo-400 {
  color: var(--black, #000);
  font-family: Archivo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.font-archivo-600 {
  color: var(--grey-700, #43434D) !important;
  text-align: center !important;
  font-family: Archivo !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.font-archivo-800 {
  color: var(--black, #000);
  font-family: Archivo !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
}

.text-center {
  text-align: center;
}


.back_arrow_container {
  margin-top: 24px;
  margin-bottom: 16px;
  align-items: start;
  // padding-left: 5px;
  div {
    padding-top: 3px;
  }
  p {
    padding-left: 24px;
  }
}

.text-align-left {
  text-align: left;
}

.info-icon-span {
  top: 2px;
  position: relative;
  padding: 3px 5px 0 0;
}

.info-icon {
  height: 13px;
  width: 13px;
}
