@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.main__card {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  max-width: 390px !important;
  width: 375px !important;
  height: 600px;
  background: #ffffff;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  background-color: #F5F5F5;
  padding: 20px 16px 22px 14px;
  justify-content: space-between;
}

.heading_text {
  position: relative;
  top: 8px;
  color: #5E5E5E;
}

.logo__image {
  display: inline-block;
  width: 150px;
}

.new_logo_image {
  width: 32px;
  height: 32px;
}

.logo_container {
  display: flex;
  align-items: center;
}

.logo_title_container {
  display: flex;
  align-items: center;
  height: 37.5px;
  margin-left: 6px;
  padding-left: 6px;
  border-left: 0.298px solid #E8E8EB;
}

.logo_title {
  color: #212025;
  font-size: 17.067px;
  font-style: normal;
  line-height: 14.222px;
  letter-spacing: -0.683px;
}

.logo_title_bold {
  /* font-family: Archivo Black; */
  font-family: 'Archivo Black', sans-serif;

  font-weight: 400;
}

.logo_title_slim {
  color: #212025;
  font-family: Archivo;
  font-size: 12px;
  font-style: normal;
  font-weight: 100;
  line-height: 12px;
  letter-spacing: 1.2px;
}