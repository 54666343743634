.expired-payment-header-content {
    border: none !important;
    margin-bottom: 45px !important;
    line-height: 20px !important;
}

.payment-header {
    margin-bottom: 0 !important;
    border: none !important;
    padding-bottom: 0 !important;
}

.error-header__warning {
    margin: 100px auto 49px !important;
}